import { Controller } from '@hotwired/stimulus'
import Trix from 'trix'

export default class extends Controller {
	static targets = ['content']
	static outlets = ['attachment']

	contentTargetConnected(element) {
		element.addEventListener('trix-file-accept', this.handleTrixFileAccept.bind(this))
		element.addEventListener('trix-attachment-add', this.handleTrixAttachmentAdd.bind(this))
	}

	handleTrixFileAccept(event) {
		const file = event.file

		if (file && this.hasAttachmentOutlet) {
			const isMedia = file.type.startsWith('image')

			if (!isMedia) {
				event.preventDefault()
				this.attachmentOutlet.upload(file)
			}
		}
	}

	handleTrixAttachmentAdd(event) {
		if (!event.attachment.file) {
			event.attachment.remove()
		}
	}
}
