import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
	static targets = ['picker', 'form']

	static values = {
		enableTime: {
			type: Boolean,
			default: false
		},
		mode: {
			type: String,
			default: 'single'
		},
		dateFormat: {
			type: String,
			default: 'F j, Y h:i K',
		}
	}

	pickerTargetConnected(element) {
		if (!element.classList.contains('flatpickr-input')) {
			flatpickr(this.pickerTarget, {
				onOpen: this.onOpen.bind(this),
				onClose: this.onClose.bind(this),
				enableTime: this.enableTimeValue,
				altFormat: this.dateFormatValue,
				mode: this.modeValue,
				static: true,
				altInput: true
			})
		}
	}

	onOpen(selectedDates, dateStr, instance) {
		if (selectedDates.length === 2) {
			instance.clear()
		}
	}

	onClose(selectedDates, dateStr, instance) {
		if (this.hasFormTarget) {
			this.formTarget.requestSubmit()
		}
	}
}
