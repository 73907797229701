// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@hotwired/turbo-rails")
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@rails/actiontext")
require("local-time").start()
require("@nathanvda/cocoon")
require("trix")

window.Rails = Rails

import "channels"
import "controllers"
import "bootstrap"
import "data-confirm-modal"

import "src/actiontext"

$(document).on("turbo:load", () => {
	$('[data-toggle="tooltip"]').tooltip()
	$('[data-toggle="popover"]').popover()
})

$(document).on("turbo:load", () => {
	$('.toast').toast('show').on('hidden.bs.toast', () => {
		$('.toast').remove()
	})
})

$(document).on("turbo:load", () => {
	$(document).on("click", ".not-collapsable", function(e){
		e.target.parent().stopPropagation();
	});
})

dataConfirmModal.setDefaults({
	commit: 'Continue',
	cancel: 'Cancel',
	commitClass: 'btn-outline-danger',
	cancelClass: 'btn-outline-primary'
});
