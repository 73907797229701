import Sortable from './sortable_controller';

export default class extends Sortable {
	static outlets = ['confetti']

	onStart(event) {
		this.board.classList.add('dragging')
	}

	onChange(event) {
		if (event.to === this.drawer) {
			this.drawer.classList.add('active')
		} else {
			this.drawer.classList.remove('active')
		}
	}

	onSpill() {
		this.drawer.classList.remove('active')
	}

	onChoose(event) {
		this.drawer.classList.remove('active')
	}

	onUnchoose(event) {
		this.board.classList.remove('dragging')
	}

	onEnd(event) {
		super.onEnd(event)

		if (event.to == this.drawer && this.hasConfettiOutlet) {
			this.confettiOutlet.trigger()
		}
	}

	appendFormData(event) {
		this.formData = new FormData()
		if (event.to == this.drawer) {
			this.formData.append('issue[complete]', true);
		} else {
			this.formData.append('issue[status_id]', event.to.dataset.statusId);
			this.formData.append('issue[position]', event.newDraggableIndex);
		}
	}

	get board() {
		return document.querySelector('.board-wrapper')
	}

	get drawer() {
		return document.querySelector('.card-drop')
	}
}
