import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['main', 'drawer']

	toggle() {
		$(this.drawerTarget).toggleClass('drawer-open')
		$(this.mainTarget).toggleClass('drawer-open')

		timeline.loaded.then(() => {
			if (this.drawerTarget.classList.contains('drawer-open')) {
				this.timeline.reload()
			}
		})
	}

	hide(event) {
		if (this.drawerTarget.contains(event.target)) return
		if (this.drawerTarget.classList.contains('drawer-open')) {
			$('.drawer-open').removeClass('drawer-open')
		}
	}

	get timeline() {
		return document.getElementById('timeline')
	}
}
