import { Controller } from '@hotwired/stimulus'
import { Sortable, OnSpill } from 'sortablejs/modular/sortable.core.esm'
import { patch } from '@rails/request.js'

Sortable.mount(OnSpill);

export default class extends Controller {
	static targets = ['sortable']

	connect() {
		if (this.hasSortableTarget) {
			this.sortable()
		}
	}

	sortable() {
		this.sortableTargets.forEach((sortable) => {
			Sortable.create(sortable, {
				animation: 150,
				forceFallback: true,
				revertOnSpill: true,
				group: this.sortableTarget.dataset.group,
				handle: this.sortableTarget.dataset.handle,
				direction: this.sortableTarget.dataset.direction,
				draggable: this.sortableTarget.dataset.draggable,
				onEnd: this.onEnd.bind(this),
				onStart: this.onStart.bind(this),
				onChange: this.onChange.bind(this),
				onChoose: this.onChoose.bind(this),
				onUnchoose: this.onUnchoose.bind(this),
				onSpill: this.onSpill.bind(this)
			})
		})
	}

	onSpill() {
	}

	onChoose() {
	}

	onUnchoose() {
	}

	onChange() {
	}

	onStart() {
	}

	appendFormData(event) {
		this.formData = new FormData()
		this.formData.append('position', event.newDraggableIndex)
	}

	onEnd(event) {
		this.appendFormData(event)
		patch(event.item.dataset.url, {
			query: this.formData,
			responseKind: 'turbo-stream'
		})
	}
}
