import Trix from 'trix'
import hljs from 'highlight.js'
import '@rails/actiontext'

Trix.config.textAttributes.inlineCode = {
	tagName: 'code',
	inheritable: true
}

Trix.config.blockAttributes.heading1 = {
	tagName: 'h5',
	inheritable: true
}

$(document).on('turbo:load', function() {	
	document.querySelectorAll('.trix-content pre').forEach((block) => {
		hljs.highlightBlock(block)
	})
})
