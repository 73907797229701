import { Controller } from '@hotwired/stimulus'
import confetti from 'canvas-confetti'

export default class extends Controller {

	trigger() {
		if (this.canvas == undefined) this.createCanvas()

		// Create confetti object
		this.confetti = confetti.create(this.canvas, { resize: true })

		// Fire!
		this.fire(0.25, { spread: 26, startVelocity: 55 })
		this.fire(0.2, { spread: 60 })
		this.fire(0.35, { spread: 100, decay: 0.7, scalar: 0.8 })
		this.fire(0.1, { speed: 150, startVelocity: 25, decay: 0.72, scalar: 1.2 })
		this.fire(0.1, { speed: 150, startVelocity: 45 })

		// Remove canvas after 5 seconds
		setTimeout(function () {
			document.body.removeChild(this.canvas)
		}, 5000)
	}

	fire(particleRatio, options) {
		let count = 200
		let posX = event.clientX/window.innerWidth
		let posY = event.clientY/window.innerHeight
		let defaults = { origin: { x: posX, y: posY } }

		this.confetti(Object.assign({}, defaults, options, {
			particleCount: Math.floor(count * particleRatio)
		}))
	}

	createCanvas() {
		document.body.insertAdjacentHTML('beforeend', '<canvas id="confetti"></canvas>')
	}

	get canvas() {
		return document.querySelector("#confetti")
	}
}
