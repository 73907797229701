import { Controller } from '@hotwired/stimulus'
import Huebee from 'huebee';

export default class extends Controller {
  static targets = ['picker']

	static values = {
		colors: {
			type: Array,
			default: ['#FA5A4C', '#FFA82D', '#F8DA1F', '#64D353', '#4EAD20', '#157EFB', '#FF2DA6', '#9C3BFA']
		},
		shades: {
			type: Number,
			default: 0
		},
		hues: {
			type: Number,
			default: 4
		}
	}

  connect() {
    if (this.hasPickerTarget) {
      this.initializeHuebee()
    }
  }

  initializeHuebee() {
		let _this = this

    $(this.pickerTargets).each(function(i, elem) {
      var hueb = new Huebee(elem, {
        customColors: _this.colorsValue,
        setBGColor: '.set-bg-elem',
        shades: _this.shadesValue,
        hues: _this.huesValue,
      })
    })
  }
}
