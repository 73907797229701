import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['card', 'error', 'token', 'form']

	connect() {
		this.stripe = Stripe(this.stripeKey)
		let elements = this.stripe.elements()
		this.card = elements.create('card')
		this.card.mount(this.cardTarget)
		this.card.on('change', this.onChange.bind(this))
	}

	onChange(event) {
		if (event.error) {
			$(this.errorTarget).text(event.error.message);
		} else {
			$(this.errorTarget).text('')
		}
	}

	submit(event) {
		event.preventDefault()
		Rails.disableElement(this.formTarget)
		this.stripe.createToken(this.card).then((result) => {
			if (result.error) {
				this.showError(result.error.message)
			} else {
				this.handleStripeToken(result.token)
			}
		})
	}

	handleStripeToken(token) {
		$(this.errorTarget).text('');
		$(this.tokenTarget).val(token.id)
		this.formTarget.submit()
	}

	showError(error) {
		$(this.errorTarget).text(error)
		setTimeout(() => {
			Rails.enableElement(this.formTarget)
		}, 100)
	}

	get stripeKey() {
		return $('meta[name="stripe-key"]').attr('content')
	}
}
