import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['project', 'company', 'message']

	filter(event) {
		const term = event.currentTarget.value.toLowerCase()

		this.toggleProjects(term)
		this.toggleCompanies()
		this.toggleMessage()
	}

	toggleProjects(term) {
		this.projectTargets.forEach((project) => {
			const title = project.textContent.toLowerCase()
			const match = title.includes(term)
			project.classList.toggle('d-none', !match)
		})
	}

	toggleCompanies() {
		this.companyTargets.forEach((company) => {
			const projects = company.parentElement.querySelectorAll('.dropdown-item:not(.d-none)')
			company.classList.toggle('d-none', projects.length == 0)
		})
	}

	toggleMessage() {
		const empty = this.projectTargets.every((project) => project.classList.contains('d-none'))
		this.messageTarget.classList.toggle('d-none', !empty)
	}
}
