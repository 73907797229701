import Sortable from './sortable_controller';

export default class extends Sortable {

	onEnd(event) {
		if (event.item.dataset.url) {
			super.onEnd(event)
		} else {
			$(event.to).find('.position').each((i, elem) => {
				$(elem).val(i + 1)
			})
		}
	}

	appendFormData(event) {
		this.formData = new FormData()
		this.formData.append('task[position]', event.newDraggableIndex);
	}
}
