import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import Tagify from '@yaireo/tagify'

export default class extends Controller {

	connect() {
		this.tagify = new Tagify(
			this.element, {
				whitelist: [],
				originalInputValueFormat: valuesArr => valuesArr.map(item => item.value).join(','),
				transformTag: this.transformTag,
				dropdown: {
					enabled: 0,
				}
			}
		)

		this.transformDropdownItems()
		this.fetchWhitelist()
	}

	transformTag(tagData) {
		if (tagData.color)
			tagData.style = `--tag-bg: ${tagData.color}30; --tag-text-color: ${tagData.color};`
	}

	fetchWhitelist() {
		get(this.element.dataset.url, {
			responseKind: 'json'
		}).then(response =>
			response.json
		).then(json => {
			this.tagify.whitelist =
			json.map(option => {
				option.value = option.name
				return option
			})
		})
	}

	transformDropdownItems() {
		this.tagify.on('dropdown:show', () => {
			$('.tagify__dropdown__item').each((_i, dropdownItem) => {
				let item =
				this.tagify.suggestedListItems.find((suggestedListItem) => {
					if (suggestedListItem.name == $(dropdownItem).text())
						return suggestedListItem
				})
				$(dropdownItem).css({ 'color': item.color, 'background-color': `${item.color}30` })
			})
		})
	}
}
