import { Controller } from '@hotwired/stimulus'
import * as Turbo from '@hotwired/turbo'

export default class extends Controller {
	static targets = ['body']

	connect() {
		$(this.element).on('turbo:submit-end', this.onSubmitEnd.bind(this))
	}

	disconnect() {
		$(this.element).off('turbo:submit-end')
	}

	bodyTargetConnected() {
		this.show()
	}

	show() {
		$(this.element).modal('show').on('shown.bs.modal', this.onShown.bind(this))
	}

	hide() {
		$(this.element).modal('hide').off('shown.bs.modal')
	}

	onShown(event) {
		if (this.autoFocus) {
			$(this.element).find('.form-control:visible:enabled:first').focus()
		}
	}

	onSubmitEnd(event) {
		if (event.detail.success && this.autoHide) {
			const redirected = event.detail.fetchResponse.response.redirected

			if (redirected) {
				$(document).one('turbo:before-render', this.onBeforeRender.bind(this))
			} else {
				this.hide()
			}
		}
	}

	onBeforeRender(event) {
		event.preventDefault()
		$(this.element).one('hidden.bs.modal', event.detail.resume)
		this.hide()
	}

	get autoFocus() {
		return this.bodyTarget.dataset.autoFocus
	}

	get autoHide() {
		return this.bodyTarget.dataset.autoHide
	}
}
