import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import { get } from '@rails/request.js'

export default class extends Controller {
	static targets = ['list', 'input', 'progress']

	static values = {
		url: String
	}

	change(event) {
		Array.from(this.inputTarget.files).forEach(file => this.upload(file))

		this.inputTarget.value = null
	}

	remove(event) {
		event.preventDefault()
		event.currentTarget.parentNode.remove()
	}

	upload(file) {
		const upload = new DirectUpload(file, this.urlValue, this)

		upload.create((error, blob) => {
			if (error) {
				alert(error)
			} else {
				get(
					`${this.urlValue}/${blob.signed_id}`
				).then(
					response => response.html
				).then(
					html => this.listTarget.innerHTML += html
				)
			}
		})
	}

	directUploadWillStoreFileWithXHR(request) {
		const upload = request.upload

		upload.addEventListener('loadstart', this.onLoadStart.bind(this))
		upload.addEventListener('progress', this.onProgress.bind(this))
		upload.addEventListener('loadend', this.onLoadEnd.bind(this))
	}

	onLoadStart(event) {
		$(this.progressTarget).removeClass('d-none')

		Rails.disableElement(this.form)
	}

	onProgress(event) {
		const progress = (event.loaded / event.total) * 100

		$(this.progressTarget).find('.progress-bar').css('width', `${progress}%`)
	}

	onLoadEnd(event) {
		$(this.progressTarget).addClass('d-none')

		Rails.enableElement(this.form)
	}

	get form() {
		return this.inputTarget.form
	}
}
